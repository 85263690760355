export default [
    {
        label: '번호',
        field: 'orderNo',
        sort: false
    },
    {
        label: '이름',
        field: 'kidsName',
        sort: false
    },
    {
        label: '성별',
        field: 'gender',
        sort: false
    },
    {
        label: '생년월일',
        field: 'birth',
        sort: false
    },
    {
        label: '진단명',
        field: 'diagnosis',
        sort: false
    },
    {
        label: '평가일자',
        field: 'regDate',
        sort: false
    },
    {
        label: '평가자',
        field: 'evaluator',
        sort: false
    },
    {
        label: 'POMP(%)',
        field: 'pomp',
        sort: false
    },
];
